.Sobre {
    main {
        display: block;
        box-sizing: border-box;
        word-wrap: break-word;

        h1, h2, h3 {
            text-align: center;
        }
        
        .title {
            h1 {
                margin: 0;
                font-size: 1.8rem;
            }

            h2 {
                color: #179e12;
                font-size: 1.8rem;
                margin: 0;
            }
        }

        > h2 {
            border-bottom: 2px solid black;
        }

        img {
            box-sizing: border-box;
            width: 50%;
            padding: .5rem;
            aspect-ratio: 1.5 / 1;
            box-sizing: border-box;
            background: linear-gradient(145deg, #179e12, yellow);
            margin-top: 2rem;
        }

        p {
            text-align: justify;
            width: 60%;
            margin: auto;
            font-size: 1.4rem;
        }

        div {
            margin: 2rem 0;
            text-align: center;
        }
    }
}


@media only screen and (max-width: 680px) {
    .Sobre {
        main {
            p {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }
    }
}