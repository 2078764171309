@keyframes flutuar {
  0%, 100% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-12px);
  }
}

.Home {
  main {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .title {
    box-sizing: border-box;
    text-align: center;

    h1 {
      font-size: 2rem;
      margin: 0;
    }
  
    h2 {
      margin: 0;
      font-size: 2rem;
    }

    span {
      color: #1d971d;
    }
  }

  .chamaNoZap {
    width: auto;
    text-align: center;
    font-size: 1.3rem;
    padding: .8rem;
    border-radius: 16px;
    background: #1d971d;
    transition: all 180ms ease-in-out;
    cursor: pointer;
    animation: flutuar 2s ease-in-out infinite;

    img {
      width: 2.2rem;
      aspect-ratio: 1 / 1;
      margin-right: .5rem;
      font-size: 2rem;
      vertical-align: middle;
    }

    * {
      color: #ffff;
    }
  }

  .chamaNoZap:hover {
    background: #198519;
  }
}

@media only screen and (max-width: 680px) {
  .Home {
    .title {  
      h1 {
        font-size: 1.4rem;
      }

      h2 {
        font-size: 1rem;
      }
    }
  }
}