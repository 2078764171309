.NotFound {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #ffff;

    h2 {
        font-size: 2rem;
    }
}