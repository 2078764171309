.Contato {
    main {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;

        h1,
        h2 {
            text-align: center;
            margin-top: 3rem;
        }

        .info div {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            align-items: center;
            margin: .3rem 0;
            font-size: 1.5rem;

            div {
                display: block;

                * {
                    margin: 0;
                }
            }

            strong {
                margin: 5px 0;
            }

            img {
                width: 2.8rem;
                margin: 0;
                aspect-ratio: 1 / 1;
                padding: 0 5px;
                font-size: 0;
            }
        }

        .location {
            width: 100%;
            text-align: center;

            .map {
                width: 55%;
                aspect-ratio: 2 / 1;
                border: 0;
            }
        }
    }
}

@media only screen and (max-width: 680px) {
    .Contato {
        main {
            padding: 0;

            h1 {
                font-size: 1.5em;
            }

            .info div {
                font-size: 1rem;
            }

            .location {
                .map {
                    width: 100%;
                }
            }
        }
    }
}