.footer {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 3rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    background: #dbd3d3;

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
            text-align: end;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                box-sizing: border-box;
                padding: 5px 0;
                
                a {
                    text-decoration: none;
                    border-bottom: 1px solid #237c23;
                }
            }
        }
        
        .left {
            div {
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
                align-items: center;
                margin: .3rem 0;

                div {
                    display: block;
                    * {
                        margin: 0;
                    }
                }
            }

            strong {
                margin: 5px 0;
            }

            img {
                width: 2rem;
                margin: 0;
                aspect-ratio: 1 / 1;
                padding: 0 5px;
                font-size: 0;
            }
        }
    }

    .copyright {
        display: block;
        text-align: center;

        h2 {
            color: #188b0e;
        }
    }
}

@media only screen and (max-width: 680px) {
    .footer {
        padding: 1rem;

        .info {
            display: block;
            
            .left {
                width: 100%;
            }
            .right {
                display: none;
            }
        }
    }
}