.navbar {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background: #ffff;
    box-shadow: 0px 15px 10px -15px #111111c4; 
    /* background: linear-gradient(180deg, #ffffff, transparent); */

    >img {
        width: 9rem;
        aspect-ratio: 2 / 1;
    }

    .menu-button {
        text-align: center;
        font-size: 2em;
    }

    ul {
        display: block;
        list-style: none;

        li {
            display: inline-block;
            margin: 0 2rem;
            
            > * {
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                padding: .5rem 0;
                margin: 0;
                border-bottom: 3px solid transparent;
                transition: all 200ms ease-in-out;
                cursor: pointer;
            }

            > *:hover, .active {
                border-color: #237c23;
            }
        }

        .dropdown {
            position: relative;
            display: inline-block;
        }

        .dropdown-btn {
            margin: 0;
            padding: 0;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            width: 100%;
            padding: .4rem 0;
            box-sizing: border-box;
            font-size: .8rem;
            background-color: #e9e9e9;
            border: 0;
            z-index: 1;

            * {
                display: block;
                margin: .5rem 0;
                padding: 0 .5rem;
                box-sizing: border-box;
                border-bottom: 2px solid transparent;
            }

            *:hover {
                border-color: #237c23;
            }
        }

        .dropdown:hover .dropdown-content {
            display: block;
        }

        .dropdown:hover .dropdown-btn {
            border-color: #237c23;
        }
    }
}

@media only screen and (max-width: 650px) {
    .navbar {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                margin: 1rem;
                word-wrap: break-word;
            }
        }
    }
}