@font-face {
  font-family: Prompt;
  src: url("/public/fonts/Prompt-Regular.ttf");
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Prompt, Roboto, sans-serif;
  overflow-x: hidden;
  background: linear-gradient(180deg, #65ea77, #bdf0be);
  background-size: cover;
}

* {
  color: #161616;
}

img, p, h1, h2, h3, h4, h5, h6, p {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

main {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 2rem;
  box-sizing: border-box;
  background: transparent;
}

.contact-info {
  transition: border 200ms ease-in-out;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.contact-info:hover {
  border-color: #237c23;
}